import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutList } from "../../Routes";
import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.Footer}>
      <div className="footer-top">
        <Link to={RoutList.HOME} className="logo">
          <img
            src="/images/footer-logo.svg"
            alt={t("footer.footerTop.logoAlt")}
            width="333"
            height="80"
          />
        </Link>
      </div>
      <ul className="social-links">
        <li className="social-item">
          <a
            href="https://www.facebook.com/aprautoudvar"
            rel="noreferrer"
            target="_blank"
          >
            <img
              width="24"
              height="24"
              src="/images/icons/facebook-primary.svg"
              alt={t("footer.footerTop.logoAlt")}
            />
          </a>
        </li>
        <li className="social-item">
          <a
            href="https://www.instagram.com/aprautoudvar/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              width="24"
              height="24"
              src="/images/icons/instagram-primary.svg"
              alt={t("footer.footerTop.logoAlt")}
            />
          </a>
        </li>
        <li className="social-item">
          <a href="mailto:aprautoudvar@gmail.com">
            <img
              width="24"
              height="24"
              src="/images/icons/at-primary.svg"
              alt={t("footer.footerTop.logoAlt")}
            />
          </a>
        </li>
      </ul>
      <nav>
        <ul className="menu-list">
          <li className="menu-item">
            <Link to={RoutList.CAR_SUPPLY}>{t("footer.menu.carSupply")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.SPECIAL_OFFER}>
              {t("footer.menu.specialOffer")}
            </Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.BUYING_CAR}>{t("footer.menu.buyingCar")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.SERVICES}>{t("footer.menu.services")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.CREDIT_MANAGEMENT}>
              {t("footer.menu.creditManagement")}
            </Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.CONTACT}>{t("footer.menu.contact")}</Link>
          </li>
        </ul>
      </nav>
      <p className="copy-text">
        &copy; <a href="/">aprautodvar.hu</a> | minden jog fenntartva | A
        weblapot készítette:
        <a href="http://www.danielpasztor.com/">Pásztor Dániel</a>
      </p>
    </footer>
  );
};

export default Footer;
