import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { RoutList } from "../../Routes";
import styles from "./Header.module.scss";

const Header: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  useEffect(() => {
    setMenuOpened(false);
  }, [location]);
  return (
    <header className={styles.Header}>
      <div className="header-top">
        <div className="header-contact">
          <a
            className="contact-item bread-text"
            href={`mailto:${t("components.ContactBox.contactLines.email")}`}
          >
            <img
              width="24"
              height="24"
              src="/images/icons/at-primary.svg"
              alt=""
            />
            {t("components.ContactBox.contactLines.email")}
          </a>
          <p className="contact-item bread-text">
            <img
              width="24"
              height="24"
              src="/images/icons/map-primary.svg"
              alt=""
            />
            {t("components.ContactBox.contactLines.address")}
          </p>
        </div>
        <Link to={RoutList.HOME} className="logo">
          <img
            src="/images/header-logo.svg"
            alt={t("header.headerTop.logoAlt")}
            width="330"
            height="75"
          />
        </Link>
        <div className="header-contact">
          <p className="bread-text contact-item">
            <img
              width="24"
              height="24"
              src="/images/icons/phone-primary.svg"
              alt=""
            />
            <strong>
              {t("components.ContactBox.contactLines.contact1.name")}:
            </strong>
            <a href={t("components.ContactBox.contactLines.contact1.link")}>
              {t("components.ContactBox.contactLines.contact1.linkText")}
            </a>
          </p>
          {/* <p className="bread-text contact-item">
            <img
              width="24"
              height="24"
              src="/images/icons/phone-primary.svg"
              alt=""
            />
            <strong>
              {t("components.ContactBox.contactLines.contact2.name")}:
            </strong>
            <a href={t("components.ContactBox.contactLines.contact2.link")}>
              {t("components.ContactBox.contactLines.contact2.linkText")}
            </a>
          </p> */}
          <button
            type="button"
            className={`hamburger-menu${menuOpened ? " menu-opened" : ""}`}
            onClick={() => setMenuOpened(!menuOpened)}
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path className="line-1" d="M-14 14.5L-0.5 1H18" />
              <path className="line-2" d="M0 8H18" />
              <path className="line-3" d="M-14 1.5L-0.5 15H18" />
            </svg>
          </button>
        </div>
      </div>
      <nav>
        <ul className={`menu-list${menuOpened ? " menu-opened" : ""}`}>
          <li className="menu-item">
            <Link to={RoutList.CAR_SUPPLY}>{t("header.menu.carSupply")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.SPECIAL_OFFER}>
              {t("header.menu.specialOffer")}
            </Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.BUYING_CAR}>{t("header.menu.buyingCar")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.SERVICES}>{t("header.menu.services")}</Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.CREDIT_MANAGEMENT}>
              {t("header.menu.creditManagement")}
            </Link>
          </li>
          <li className="menu-item">
            <Link to={RoutList.CONTACT}>{t("header.menu.contact")}</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
