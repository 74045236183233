import React, { Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Spinner from "./components/Spinner/Spinner";

const Home = React.lazy(() => import("./pages/Home/Home"));
const CarSupply = React.lazy(() => import("./pages/CarSupply/CarSupply"));
const CarView = React.lazy(() => import("./pages/CarView/CarView"));
const SpecialOffer = React.lazy(
  () => import("./pages/SpecialOffer/SpecialOffer")
);
const Services = React.lazy(() => import("./pages/Services/Services"));
const BuyingCar = React.lazy(() => import("./pages/BuyingCar/BuyingCar"));
const CreditManagement = React.lazy(
  () => import("./pages/CreditManagement/CreditManagement")
);
const Contact = React.lazy(() => import("./pages/Contact/Contact"));

export enum RoutList {
  HOME = "/",
  CAR_SUPPLY = "/autokinalat",
  SPECIAL_OFFER = "/kiemelt-ajanlatok",
  SERVICES = "/szolgalatasaink",
  BUYING_CAR = "/autofelvasarlas",
  CREDIT_MANAGEMENT = "/hitelugyintezes",
  CONTACT = "/kapcsolat",
}

const Routes: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Switch>
      <Route path={RoutList.CONTACT}>
        <Suspense fallback={<Spinner />}>
          <Contact />
        </Suspense>
      </Route>
      <Route path={RoutList.CREDIT_MANAGEMENT}>
        <Suspense fallback={<Spinner />}>
          <CreditManagement />
        </Suspense>
      </Route>
      <Route path={RoutList.BUYING_CAR}>
        <Suspense fallback={<Spinner />}>
          <BuyingCar />
        </Suspense>
      </Route>
      <Route path={RoutList.SERVICES}>
        <Suspense fallback={<Spinner />}>
          <Services />
        </Suspense>
      </Route>
      <Route path={`${RoutList.CAR_SUPPLY}/:id`}>
        <Suspense fallback={<Spinner />}>
          <CarView />
        </Suspense>
      </Route>
      <Route path={RoutList.CAR_SUPPLY}>
        <Suspense fallback={<Spinner />}>
          <CarSupply />
        </Suspense>
      </Route>
      <Route path={RoutList.SPECIAL_OFFER}>
        <Suspense fallback={<Spinner />}>
          <SpecialOffer />
        </Suspense>
      </Route>
      <Route path={RoutList.HOME}>
        <Suspense fallback={<Spinner />}>
          <Home />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default Routes;
