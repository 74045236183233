import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const backendOptions = {
  loadPath: "/locales/{{lng}}.json",
  crossDomain: true,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR) // passes i18n down to react-i18next
  .init({
    backend: backendOptions,
    // resources,
    lng: "hu",

    debug: false,
    fallbackLng: false,
    react: {
      wait: true,
    },

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
