import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routs from "../../Routes";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routs />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
